import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)": [8,[3]],
		"/(app)/about": [~9,[3]],
		"/(account)/account": [4,[2]],
		"/(account)/account/billing": [~5,[2]],
		"/(account)/account/profile": [6,[2]],
		"/(account)/account/subscription": [~7,[2]],
		"/(app)/checkout": [~10,[3]],
		"/(app)/docs": [11,[3]],
		"/(app)/exchanges": [~12,[3]],
		"/(app)/get-tickerdata": [13,[3]],
		"/(app)/premium": [~14,[3]],
		"/(app)/privacy-policy": [15,[3]],
		"/(app)/reach-out": [~16,[3]],
		"/(app)/registered": [17,[3]],
		"/(app)/sign-in": [~18,[3]],
		"/(app)/terms-of-service": [19,[3]],
		"/(app)/test": [20,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';